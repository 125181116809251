@use 'settings' as *;

button {
  all: unset;
  outline: revert;
  cursor: pointer;
}

.button {
  font-family: $font-family;
  background: $white-color;
  color: $black-color;
  font-size: 0.875rem;
  line-height: 1.1;
  font-weight: 600;
  min-height: 42px;
  appearance: none;
  border-radius: $border-radius-round;
  border: 1px solid color($primary-palette);
  padding: #{$padding * 1.5}px #{$padding * 3}px;
  cursor: pointer;
  transition:
    background 250ms,
    color 250ms,
    border-color 250ms;
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;
  box-sizing: border-box;

  &:before {
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(0.8);
    transform-origin: center center;
    transition:
      transform 250ms,
      box-shadow 250ms;
    border-radius: $border-radius-round;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
  }

  .material-symbols-rounded {
    font-size: 22px;
    margin-right: 0;
    margin-top: -1px;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    border-color: color($primary-palette, 600);
    color: $black-color;

    &:before {
      transform: scale(1);
    }
  }

  &:active {
    border-color: color($primary-palette);
    background: contrast($primary-palette);
    color: color($primary-palette);

    &:before {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:before {
      display: none;
    }
  }
}

.button--has-icon {
  .material-symbols-rounded {
    margin-left: -8px;
    margin-right: 4px;
  }
}

.button-primary {
  @extend .button;

  border-color: color($primary-palette);
  background: color($primary-palette);
  color: contrast($primary-palette);

  &:hover {
    border-color: color($primary-palette);
    background: color($primary-palette);
    color: contrast($primary-palette);
  }

  &:active {
    border-color: color($primary-palette, 300);
    background: color($primary-palette, 300);
    color: contrast($primary-palette, 300);
  }
}

.button-secondary {
  @extend .button;

  border-color: color($secondary-palette);
  background: color($secondary-palette);
  color: contrast($secondary-palette);

  &:hover {
    border-color: color($secondary-palette);
    background: color($secondary-palette);
    color: contrast($secondary-palette);
  }

  &:active {
    border-color: color($secondary-palette, 300);
    background: color($secondary-palette, 300);
    color: contrast($secondary-palette, 300);
  }
}

.button-danger {
  @extend .button;

  border-color: color($error-palette, 600);
  background: color($error-palette, 600);
  color: $white-color;

  &:hover {
    border-color: color($error-palette);
    background: color($error-palette);
    color: $white-color;
  }

  &:active {
    border-color: color($error-palette);
    background: color($error-palette);
    color: $white-color;
  }
}

.button-remove {
  cursor: pointer;
  color: color($error-palette, 600);
  position: relative;
  transition: color 250ms;
  font-size: 0.875rem;
  line-height: 1.1;
  font-weight: 600;
  min-height: 40px;
  appearance: none;
  align-items: center;
  user-select: none;

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background: color($error-palette, 600);
    position: absolute;
    bottom: 10px;
    opacity: 0;
    transition: opacity 250ms;
  }

  &:hover,
  &:focus,
  &:active {
    &:after {
      opacity: 1;
    }
  }
}

@keyframes spin-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.spin {
  transform-style: preserve-3d;
  animation: spin-rotate 2000ms linear infinite;
}
